
import { defineComponent, PropType, ref } from "vue";

import { FieldValue } from "@tager/admin-ui";
import { useToast } from "@tager/admin-services";

import {
  AddressFormFields,
  OrderFullInterface,
} from "@/modules/orders/typings";
import {
  setOrderAddress,
  setOrderAddressWithId,
} from "@/modules/orders/services";
import OrdersAddress from "@/modules/orders/containers/OrdersAddress.vue";
import { AddressType } from "@/enums/AddressType";

import OrdersEditEditButtons from "../views/orders-edit/components/OrdersEditEditButtons.vue";

export default defineComponent({
  name: "OrdersEditAddress",
  components: {
    OrdersAddress,
    FieldValue,
    OrdersEditEditButtons,
  },
  props: {
    order: {
      type: Object as PropType<OrderFullInterface>,
      required: true,
    },
    withEdit: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup(props) {
    const toast = useToast();

    const addressId = ref<number>(props.order.address.id);

    const addressValue = ref<AddressFormFields>();

    const onAddressChange = (value: AddressFormFields) => {
      addressValue.value = value;
    };

    const addressLoading = ref<boolean>(false);
    const isEdit = ref<boolean>(false);
    const addressTextValue = ref<string>(props.order.address.text);

    const onEditChange = () => {
      isEdit.value = !isEdit.value;

      addressLoading.value = false;
    };

    const onSubmit = async () => {
      addressLoading.value = true;

      try {
        let response;
        if (addressValue.value?.id) {
          response = await setOrderAddressWithId(
            props.order.id,
            addressValue.value.id
          );
          addressTextValue.value = response.data.address;
          addressId.value = addressValue.value?.id;
        } else {
          response = await setOrderAddress(props.order.id, {
            street: addressValue?.value?.street || null,
            house: addressValue?.value?.house || null,
            apartment: addressValue?.value?.apartment || null,
            wing: addressValue?.value?.wing || null,
            entrance: addressValue?.value?.entrance || null,
            intercomCode: addressValue?.value?.intercomCode || null,
            floor: addressValue?.value?.intercomCode
              ? +addressValue?.value?.intercomCode
              : null,
            city: addressValue.value?.city?.value || null,
            type: addressValue.value?.type?.value || AddressType.Apartment,
          });

          addressTextValue.value = response.data.address;
          addressId.value = response.data.id;
        }

        isEdit.value = false;

        toast.show({
          variant: "success",
          title: "Успешно",
          body: "Адрес успешно изменен",
        });
      } catch (error) {
        toast.show({
          variant: "danger",
          title: "Ошибка",
          body: "Ошибка изменения адреса",
        });
      } finally {
        addressLoading.value = false;
      }
    };

    return {
      userId: props.order.user.id,
      addressId,
      onAddressChange,
      addressTextValue,
      isEdit,
      onEditChange,
      onSubmit,
      addressLoading,
    };
  },
});
